<template>
  <!-- 用于 路由组件 的统一样式 -->
  <div class="content-container">
    <div class="content-title" v-if="hasTitle">
      <a-row type="flex" justify="space-between">
        <a-col>
          <h3><slot name="contentTitle"></slot></h3>
        </a-col>
        <a-col>
          <a-space>
            <slot name="contentTitleActions"></slot>
            <a-button
              v-if="showRefresh"
              type="ghost"
              shape="circle"
              size="small"
              @click="handleRefresh()"
            >
              <template #icon>
                <RedoOutlined />
              </template>
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </div>
    <div :class="['content-body',{'no-footer':!hasFooter},{'no-title':!hasTitle}]">
      <div class="body-wrap" ref="contentBodyWrap">
        <slot name="contentBody" :contentHeight="contentHeight"></slot>
      </div>
    </div>
    <div class="content-footer" v-if="hasFooter">
      <slot name="contentFooter"></slot>
    </div>
  </div>

</template>

<script>
import { useSlots, ref, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex"
import { Button, Col, Row, Space } from 'ant-design-vue';

export default ({
  name: "Content",
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    AButton: Button,
  },
  props: {
    showRefresh: {
      type: Boolean,
      default: true,
    }
  },
  setup () {
    const { getters, commit, dispatch } = useStore();
    const hasFooter = ref(!!useSlots().contentFooter);
    const hasTitle = ref(!!useSlots().contentTitle);
    const contentBodyWrap = ref(null);
    const contentHeight = ref(500);
    // const contentHeight = computed(()=>{
    //    if(contentBodyWrap.value && contentBodyWrap.value.offsetHeight){
    //       return contentBodyWrap.value.offsetHeight;
    //    }
    //    return 500;
    // });
    let debounceTimer = null;
    const calcHeight = ()=>{
       if(contentBodyWrap.value && contentBodyWrap.value.offsetHeight){
          contentHeight.value = contentBodyWrap.value.offsetHeight;
       }
    }

    const debounceCalc = ()=>{
      if(debounceTimer){
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(calcHeight,200);
    }

    let refreshTimer = null;
    const handleRefresh = () => {
      clearTimeout(refreshTimer);
      commit("app/UPDATE_ROUTER_ACTIVE", false);
      refreshTimer = setTimeout(() => {
        commit("app/UPDATE_ROUTER_ACTIVE", true);
      }, 500);
    }

    onMounted(()=>{
      calcHeight();
      window.addEventListener("resize",debounceCalc);
    });

    onBeforeUnmount(()=>{
      window.removeEventListener("resize", debounceCalc)
    })

    return {
      hasFooter,
      hasTitle,
      contentBodyWrap,
      contentHeight,
      calcHeight,
      handleRefresh,
    };
  }
})
</script>

<style lang="less" scoped>
.content-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction:column;
}
.content-title {
  // position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  min-height: 40px;
}
.content-body {
  min-height: 300px;
  width: 100%;
  height: 100%;
  padding: 16px 0px 16px;
flex-grow: 1;
  box-sizing: border-box;
  overflow: hidden;
}
.content-body .body-wrap {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-footer {
  // position: absolute;
  // bottom: 0px;
  // left: 0px;
  width: 100%;
  // z-index: 1;
  // background: #fff;
  min-height: 40px;
}
.content-body.no-footer {
  padding-bottom: 0;
}
.content-body.no-title {
  padding-top: 0;
}
</style>