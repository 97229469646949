import client from "../../client";

const Api = {
  getMyProductInfo: "/api/ProductService/Product/GetSkuCount",
  getStorageAndConsignmentInfo:
    "/api/WarehouseService/InventoryStatistics/GetSdWhOverview",
  getInAndOutPlanInfo:
    "/api/WarehouseService/InventoryStatistics/GetInOutWhPlanOverview",
  getTransportAndOrderInfo:
    "/api/WarehouseService/InventoryStatistics/GetTPlanOrderOverview",
};

export const getMyProductInfo = () => {
  return client.request({
    url: Api.getMyProductInfo,
    // data: { },
    method: "get",
  });
};

export const getStorageAndConsignmentInfo = () => {
  return client.request({
    url: Api.getStorageAndConsignmentInfo,
    method: "get",
  });
};

export const getInAndOutPlanInfo = () => {
  return client.request({
    url: Api.getInAndOutPlanInfo,
    method: "get",
  });
};

export const getTransportAndOrderInfo = () => {
  return client.request({
    url: Api.getTransportAndOrderInfo,
    method: "get",
  });
};
