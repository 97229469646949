<template>
  <Content :show-refresh="false">
    <template #contentTitle>
      {{ $t("menu.overview") }}
      <a-button type="ghost"
                shape="circle"
                size="small"
                @click="getPageInfo"
                :loading="myProduct.loading">
        <template #icon>
          <RedoOutlined />
        </template>
      </a-button>
    </template>
    <template v-slot:contentBody>
      <div>
        <a-row type="flex"
               align="top"
               :wrap="true"
               :gutter="[48, 30]">
          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.my_product')">
              <a-spin :spinning="myProduct.loading">
                <a-row type="flex"
                       justify="center"
                       align="top">
                  <a-col class="text-center">
                    <div>{{$t('warehouse.sku_number')}}</div>
                    <div class="number-size">
                      <strong>{{myProduct.seskuCount}}</strong>
                    </div>
                  </a-col>
                </a-row>
              </a-spin>
            </a-card>
          </a-col>
          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.storage_warehouse')">
              <a-spin :spinning="storageAndConsignment.loading">
                <a-row type="flex"
                       justify="space-between"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.on_warehouse_sku_number')}}</div>
                    <div class="number-size">{{storageAndConsignment.storageWarehouseInSkuCount}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.inventory_box_count')}}</div>
                    <div class="number-size">{{storageAndConsignment.storageWarehouseBoxCount}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.estimated_inventory_quantity')}}</div>
                    <div class="number-size">{{storageAndConsignment.storageWarehouseEstimateCount}}</div>
                  </a-col>
                </a-row>
              </a-spin>
            </a-card>
          </a-col>
          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.consignment_warehouse')">
              <a-spin :spinning="storageAndConsignment.loading">
                <a-row type="flex"
                       justify="space-around"
                       align="top"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.on_warehouse_sku_number')}}</div>
                    <div class="number-size">{{storageAndConsignment.delegateWarehouseInSkuCount}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.inventory_count')}}</div>
                    <div class="number-size">{{storageAndConsignment.delegateWarehouseInventoryCount}}</div>
                  </a-col>
                </a-row>
              </a-spin>

            </a-card>
          </a-col>

          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.inbound_plan')">
              <a-spin :spinning="inAndOutPlan.loading">

                <a-row type="flex"
                       justify="space-around"
                       align="top"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.planing')}}</div>
                    <div class="number-size text-primary">{{inAndOutPlan.inWarehousePlanPlaning}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.await_receive_in_pan')}}</div>
                    <div class="number-size">{{inAndOutPlan.inWarehousePlanWaitStorage}}</div>
                  </a-col>
                </a-row>
              </a-spin>

            </a-card>
          </a-col>

          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.transport_plan')">
              <a-spin :spinning="transportAndOrder.loading">
                <a-row type="flex"
                       justify="space-around"
                       align="top"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.planing')}}</div>
                    <div class="number-size text-primary">{{transportAndOrder.transportPlanPlaning}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.await_pay_transport_plan')}}</div>
                    <div class="number-size">{{transportAndOrder.transportPlanWaitPay}}</div>
                  </a-col>
                </a-row>
              </a-spin>

            </a-card>
          </a-col>

          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.consignment_order')">
              <a-spin :spinning="transportAndOrder.loading">
                <a-row type="flex"
                       justify="space-around"
                       align="top"
                       class="text-center">
                  <a-col>
                    <div>{{$t('warehouse.await_operate_consignment_order')}}</div>
                    <div class="number-size text-primary">{{transportAndOrder.agentOrderWaitOper}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.placed_an_order')}}</div>
                    <div class="number-size text-success">{{transportAndOrder.agentOrderPlaceOrder}}</div>
                  </a-col>
                  <a-col>
                    <div>{{$t('warehouse.order_in_process')}}</div>
                    <div class="number-size">{{transportAndOrder.agentOrderProcessOrder}}</div>
                  </a-col>
                </a-row>
              </a-spin>

            </a-card>
          </a-col>

          <a-col :xl="{ span: 8 }"
                 :md="{ span: 12 }"
                 :sm="{ span: 24 }"
                 :xs="{ span: 24 }">
            <a-card :title="$t('warehouse.out_pan')">
              <a-spin :spinning="inAndOutPlan.loading">

                <a-row type="flex"
                       justify="space-around"
                       align="top"
                       class="text-center">
                  <a-col :md=" 12"
                         :sm=" 24"
                         :xs=" 24">
                    <div>{{$t('warehouse.submitted')}}</div>
                    <div class="number-size text-primary">{{inAndOutPlan.outWarehousePlanPlaning}}</div>
                  </a-col>
                  <a-col :md=" 12"
                         :sm=" 24"
                         :xs=" 24">
                    <div>{{$t('warehouse.planing')}}</div>
                    <div class="number-size ">{{inAndOutPlan.outWarehousePlanInTheWorks}}</div>
                  </a-col>
                  <a-col :md=" 12"
                         :sm=" 24"
                         :xs=" 24">
                    <div>{{$t('warehouse.in_the_outbound_plan')}}</div>
                    <div class="number-size">{{inAndOutPlan.outWarehousePlanRetrivaling}}</div>
                  </a-col>
                  <a-col :md=" 12"
                         :sm=" 24"
                         :xs=" 24">
                    <div>{{$t('warehouse.await_logistics_pan')}}</div>
                    <div class="number-size">{{inAndOutPlan.outWarehousePlanWaitLogistics}}</div>
                  </a-col>
                </a-row>
              </a-spin>

            </a-card>
          </a-col>

        </a-row>
      </div>
    </template>
  </Content>
</template>
<script>
import { reactive, onMounted, toRefs } from "vue";
import { Row, Col, Card, Spin, Button } from "ant-design-vue";
import Content from "../components/Content.vue";
import { getMyProductInfo, getStorageAndConsignmentInfo, getInAndOutPlanInfo, getTransportAndOrderInfo } from "../../api/modules/overview/overview";

export default {
  name: "dashboard_index",
  components: {
    Content,
    ARow: Row,
    ACol: Col,
    ACard: Card,
    ASpin: Spin,
    AButton: Button
  },
  setup () {
    const state = reactive({
      myProduct: {
        seskuCount: 0,
        loading: false
      },
      storageAndConsignment: {
        storageWarehouseInSkuCount: 0,
        storageWarehouseBoxCount: 0,
        storageWarehouseEstimateCount: 0,
        delegateWarehouseInSkuCount: 0,
        delegateWarehouseInventoryCount: 0,
        loading: false
      },
      inAndOutPlan: {
        inWarehousePlanPlaning: 0,
        inWarehousePlanWaitStorage: 0,
        outWarehousePlanPlaning: 0,
        outWarehousePlanInTheWorks: 0,
        outWarehousePlanRetrivaling: 0,
        outWarehousePlanWaitLogistics: 0,
        loading: false
      },
      transportAndOrder: {
        transportPlanPlaning: 0,
        transportPlanWaitPay: 0,
        agentOrderWaitOper: 0,
        agentOrderPlaceOrder: 0,
        agentOrderProcessOrder: 0,
        loading: false
      }
    });

    const getPageInfo = async () => {
      state.myProduct.loading = true;
      getMyProductInfo().then((res) => {
        let { result } = res;
        if (result) {
          Object.assign(state.myProduct, result);
        }
        state.myProduct.loading = false;
      }).catch(() => {
        state.myProduct.loading = false;
      })

      state.storageAndConsignment.loading = true;
      getStorageAndConsignmentInfo().then((res) => {
        let { result } = res;
        if (result) {
          Object.assign(state.storageAndConsignment, result);
        }
        state.storageAndConsignment.loading = false;
      }).catch(() => {
        state.storageAndConsignment.loading = false;
      })

      state.inAndOutPlan.loading = true;
      getInAndOutPlanInfo().then((res) => {
        let { result } = res;
        if (result) {
          Object.assign(state.inAndOutPlan, result);
        }
        state.inAndOutPlan.loading = false;
      }).catch(() => {
        state.inAndOutPlan.loading = false;
      })

      state.transportAndOrder.loading = true;
      getTransportAndOrderInfo().then((res) => {
        let { result } = res;
        if (result) {
          Object.assign(state.transportAndOrder, result);
        }
        state.transportAndOrder.loading = false;
      }).catch(() => {
        state.transportAndOrder.loading = false;
      })
    }
    onMounted(() => {
      getPageInfo();
    });

    return {
      ...toRefs(state),
      getPageInfo,
    }
  },
};
</script>
<style lang="less">
.number-size {
  font-size: 40px;
}
</style>